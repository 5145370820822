import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Pagination, Navigation } from 'swiper/modules';

const data = [

  {
    name: "A1",
    image: "/home5_residence/assets/images/floor-plans/A1.jpg",
    bedroom: "JR. 1 BEDROOM + FLEX",
    sqft: "511 SQ FT"
  },
  {
    name: "B1",
    image: "/home5_residence/assets/images/floor-plans/B1.jpg",
    bedroom: "1 BEDROOM",
    sqft: "667 SQ FT"
  },
  {
    name: "B2",
    image: "/home5_residence/assets/images/floor-plans/B2.jpg",
    bedroom: "1 BEDROOM",
    sqft: "654 SQ FT"
  },
  {
    name: "B3",
    image: "/home5_residence/assets/images/floor-plans/B3.jpg",
    bedroom: "1 BEDROOM + FLEX",
    sqft: "676 SQ FT"
  },
  {
    name: "C1",
    image: "/home5_residence/assets/images/floor-plans/C1.jpg",
    bedroom: "1 BEDROOM + FLEX",
    sqft: "593 SQ FT"
  },
  {
    name: "C2",
    image: "/home5_residence/assets/images/floor-plans/C2.jpg",
    bedroom: "1 BEDROOM + FLEX",
    sqft: "631 SQ FT"
  },
  {
    name: "C3",
    image: "/home5_residence/assets/images/floor-plans/C3.jpg",
    bedroom: "1 BEDROOM +FLEX (ADAPTABLE)",
    sqft: "829 SQ FT"
  },
  {
    name: "C4",
    image: "/home5_residence/assets/images/floor-plans/C4.jpg",
    bedroom: "1 BEDROOM + FLEX",
    sqft: "719 SQ FT"
  },
  {
    name: "D1",
    image: "/home5_residence/assets/images/floor-plans/D1.jpg",
    bedroom: "2 BEDROOM",
    sqft: "753 SQ FT"
  },
  {
    name: "D2",
    image: "/home5_residence/assets/images/floor-plans/D2.jpg",
    bedroom: "2 BEDROOM",
    sqft: "887 SQ FT"
  },
  {
    name: "D3",
    image: "/home5_residence/assets/images/floor-plans/D3.jpg",
    bedroom: "2 BEDROOM",
    sqft: "987 SQ FT"
  },
  {
    name: "D4",
    image: "/home5_residence/assets/images/floor-plans/D4.jpg",
    bedroom: "2 BEDROOM",
    sqft: "991 SQ FT"
  },
  {
    name: "D5",
    image: "/home5_residence/assets/images/floor-plans/D5.jpg",
    bedroom: "2 BEDROOM",
    sqft: "993 SQ FT"
  },
  {
    name: "E1",
    image: "/home5_residence/assets/images/floor-plans/E1.jpg",
    bedroom: "2 BEDROOM + FLEX",
    sqft: "935 SQ FT"
  },
  {
    name: "E2",
    image: "/home5_residence/assets/images/floor-plans/E2.jpg",
    bedroom: "2 BEDROOM + FLEX",
    sqft: "975 SQ FT"
  },
  {
    name: "E3",
    image: "/home5_residence/assets/images/floor-plans/E3.jpg",
    bedroom: "2 BEDROOM + FLEX",
    sqft: "1080 SQ FT"
  },
];


function FloorPlans() {
  return (
    <section id="floorplans" class="container" style={{ paddingTop: '100px', paddingBottom: '60px' }}>

      <h2 className="fsz-50 text-capitalize color-2626 fw-400 text-center mb-70 js-splittext-lines">
        floorplans
      </h2>

      <div>
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          loop={true}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Pagination, Navigation]}
          className="mySwiper"
        >

          {data.map((item, i) => (
            <SwiperSlide key={i}>
              <div className="container">
                <div className="row">
                  <div className="col-12 text-center">
                    <img src={item.image} alt="Floor Plan" width={'500px'} />
                  </div>
                </div>
                <div className="row text-center mb-2">
                  <div className="col-12 " >
                    <h3 className='color-2626'>Unit {item.name}</h3>
                    <h6 className='color-2626'>{item.bedroom}</h6>
                    <h6 className='color-2626'>{item.sqft}</h6>
                  </div>
                </div>

                <div className='row text-end mb-5'>
                  <div className="col-12 floor-plan-download">
                    <a
                      href={`/floor-plan-pdfs/${item.name}.pdf`}
                      className="color-2626" target='_blank'
                    >
                      <span className="icon">
                        <i className="fal fa-arrow-to-bottom"></i>
                      </span>
                     
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}

        </Swiper>
      </div>

    </section>
  );
}

export default FloorPlans;
