import React from 'react';

function About() {
  return (
    <section className="tc-about-style5">
      <div className="container">
        <div className="content">
          <h2 className="about-title mb-150 js-splittext-lines color-2626">
            Introducing <span className='text-lowercase'>a</span> prestigious <br /> residential project designed to
            <span className="color-2626"> redefine luxury living</span>
          </h2>
          <div className="main-content">
            <div className="row justify-content-between">
              {/* <div className="col-lg-2 wow fadeInUp slow text-center" data-wow-delay="0.2s" >
                <div className="text color-2626 text-capitalize">
                  exclusive from <br /> Maybourne <br /> Properties
                </div>
                <div className="logo-mo mt-60 th-160 op-8" style={{"text-align": "-webkit-center"}}>
                  <img
                    src="/home5_residence/assets/images/maybourne-logo-dark.svg"
                    alt=""
                    className="img-cover scale-1-3"
                    style = {{width: "300px"}}

                  />
                </div>
              </div> */}
              <div className="col-lg-8">
                <div className="img wow zoomIn slow" data-wow-delay="0.2s">
                  <img
                    src="/home5_residence/assets/images/header.jpg"
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-3">
                <h6 className="color-2626 text-capitalize fsz-24 lh-4 js-splittext-lines">
                  Welcome to <br /> Willow <br />
                </h6>
                <p
                  className="fsz-13 mt-20 wow zoomIn slow color-2626"
                  data-wow-delay="0.2s"
                >
                  Willow is a luxurious 96-unit residential project that
                  boasts exceptional design, outstanding amenities, and a prime
                  central location. Situated in the heart of the city, this
                  apartment complex offers a unique blend of contemporary
                  architecture and a vibrant community atmosphere.
                </p>
                <a
                  href="/"
                  className="butn border rounded-pill hover-bg-black text-uppercase border-black1 color-000 mt-100 wow fadeInUp slow"
                  data-wow-delay="0.3s"
                >
                  <span> about project </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <img
        src="/home5_residence/assets/images/about.png"
        alt=""
        className="float_img"
      /> */}
      <span className="lg-txt js-title sub-font"> about </span>
    </section>
  );
}

export default About;
